






















import { Component, Vue } from 'vue-property-decorator'
import { getNavItems } from '@/helper/helper'

interface NavItem {
  name: string
  path: string
}
@Component
export default class Nav extends Vue {
  private get navItems(): NavItem[] {
    return getNavItems(this.$router, true)
  }
}
